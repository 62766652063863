<template>
	<div class="mobile-id">
		<ValidationObserver
			v-if="!smartCodeSent && !smartLoggedIn"
			v-slot="{handleSubmit}">
			<form
				@submit.prevent="smartLoginBusy ? '' : handleSubmit(login)">
				<ValidationProvider
					v-if="project == 'eKool'"
					v-slot="{errors}"
					mode="passive"
					rules="required"
					name="country"
					slim
					class="mb-4">
					<div class="pt-4">
						<e-select
							v-model="country"
							large=""
							class="body-2"
							:error="errors[0] && errors[0] !== ''"
							:errorMessages="errors[0]"
							:label="$i18n.t('smart_id_login.choose_country')"
							:items="countries"
							:placeholder="$i18n.t('setup_account.select')"
							item-value="iso"
							item-text="name" />
					</div>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{errors}"
					mode="passive"
					rules="required"
					name="id-code"
					class="mb-4"
					slim>
					<div class="pt-4">
						<e-text-field
							v-model="idCode"
							large=""
							:label="$i18n.t('mobile_id_login.id_code')"
							:error="errors[0] && errors[0] !== ''"
							:error-messages="errors[0]" />
					</div>
				</ValidationProvider>

				<div class="d-block text-center mx-auto">
					<vue-recaptcha
						v-show="smartShowRecaptcha"
						ref="recaptcha"
						v-model="recaptchaCode"
						class="recaptcha"
						:sitekey="recaptcha.siteKey"
						size="normal"
						:load-recaptcha-script="true"
						@verify="onVerify" />
				</div>
				<e-button
					class="mt-6 callout-3"
					:block="true"
					type="submit"
					:loading="smartLoginBusy"
					variant="primary"
					size="large"
					large>
					{{ $t('password_login.login') }}
				</e-button>
			</form>
		</validationobserver>
		<div
			v-if="smartCodeSent && !smartLoggedIn">
			<p class="large-title text-center mb-3">
				{{ smartCode }}
			</p>
			<p class="subhead-1 text-center mb-0 px-10">
				{{ $t('smart_id_login.check_code') }}
			</p>
			<div class="text-center mt-6">
				<e-button
					variant="link"
					small=""
					@click="cancelPing()">
					{{ $t('smart_id_login.cancel') }}
				</e-button>
			</div>
		</div>
		<!-- To do -->
		<div
			v-if="smartLoggedIn"
			class="text-center">
			<e-circular-loading
				class="pt-4"
				value
				:fullPage="false"
				:size="42" />
			<p class="subhead-1 text-center mb-0 px-10 mt-10">
				{{ $t('smart_id_login.auth_successful') }}
			</p>
		</div>
	</div>
</template>

<script>
import {
//loginCardSmart,
//loginCardSmartCheck,
//registerCardSmart,
//registerCardSmartCheck,
} from "../api";
import { mapGetters } from 'vuex';
import VueRecaptcha from "vue-recaptcha";
import { getEnv } from "../env";

export default {
	components: {
		VueRecaptcha,
	},
	props: {
		register: Boolean,
	},
	data () {
		return {
			project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
			country: undefined,
			idCode: "",
			accounts: {},
			user: {},
			code: "",
			ping: false,
			recaptchaCode: '',
			recaptcha: {
				showRecaptcha: false,
				siteKey: getEnv('VUE_APP_FRONTEND_RECAPTCHA_SITE_KEY'),
			},
			countries: [
				{ iso: "EE", name: "Estonia" },
				{ iso: "LV", name: "Latvia" },
				{ iso: "LT", name: "Lithuania" },
			],
		};
	},
	computed: {
		...mapGetters([
			'smartLoginBusy',
			'smartError',
			'smartLoggedIn',
			'smartLoginUrl',
			'smartShowRecaptcha',
			'smartSuccess',
			'smartErrors',
			'smartCodeSent',
			'smartCode',
			'smartAccounts',
			'smartUser',
			'smartPing',
			'smartTermsRequired',
		]),
	},
	mounted (){
		if(this.project == 'Mykoob'){
			this.country = 'LV';
		}
	},
	methods: {
		login () {
			if (this.register == false) {
				this.loginSmartId();
			} else {
				this.registerSmartId();
			}

		},

		//login
		loginSmartId () {
			var country = this.country;
			var personalCode = this.idCode;
			var recaptcha = this.recaptchaCode;
			this.$store.commit("resetSmartState");
			this.$store.dispatch('loginCardSmart', { country, personalCode, recaptcha }).then(
				() => {

					if(this.smartError && this.smartErrors !== ""){
						if(this.$te("smart_id_login." + this.smartErrors)){
							this.$store.commit("changeError",
								("smart_id_login." + this.smartErrors));
							this.$store.commit("showError",true);
						}
						else{
							this.$store.commit("changeError",
								("repeating_errors." + this.smartErrors));
							this.$store.commit("showError",true);
						}
					}
					if(this.smartCodeSent && !this.smartShowRecaptcha){
						this.loginPingSmartId();
					}

				},


			)
		},
		//register
		registerSmartId () {
			var country = this.country;
			var personalCode = this.idCode;
			var recaptcha = this.recaptchaCode;
			this.$store.commit("resetSmartState");
			this.$store.dispatch('registerCardSmart', { country, personalCode, recaptcha }).then(
				() => {

					//this.loginPingSmartId();
					if(this.smartError && this.smartErrors !== ""){
						this.$store.commit("changeError",
							("smart_id_login." + this.smartErrors));
						this.$store.commit("showError");
					}else if(this.smartError && this.smartErrors == ""){
						this.$store.commit("changeError",
							("smart_id_login." + this.smartErrors));
						this.$store.commit("showError",true);
					}else if(this.smartShowRecaptcha){
						this.resetRecaptcha();
					}
					else if(this.smartCodeSent){
						this.registerPingSmartId();
					}
				},


			)
		},
		onVerify (response) {
			this.recaptcha.recaptchaCode = response;
		},
		resetRecaptcha () {
			this.$refs.recaptcha.reset(); // Direct call reset method
		},
		/*
		checkSmartIdStatus () {
			loginCardSmartCheck(this.recaptcha.recaptchaCode);
		},
		*/
		loginPingSmartId () {
			this.ping = setInterval(() => {
				if(this.smartTermsRequired){
					this.$router.push({
						name: "agreeToTerms",
					});
				}
				if(this.smartPing){
					this.$store.dispatch("loginPingSmart");
				}
				else{
					clearInterval(this.ping);
					this.cancelPing();
				}
				/*
				if(this.smartError && this.smartErrors){
					console.log(1)
					if(this.$te("smart_id_login." + this.smartErrors)){
						this.$store.commit("changeError",
							("smart_id_login." + this.smartErrors));
						this.$store.commit("showError",true);
					}
					else{
						this.$store.commit("changeError",
							("repeating_errors." + this.smartErrors));
						this.$store.commit("showError",true);
					}
				}
				*/
				if(this.smartLoggedIn){
					window.top.location.href = this.smartLoginUrl;
				}
				if(this.smartAccounts){
					this.$router.push({
						name: "chooseUser",
						params: {
							users: this.smartAccounts,
						},
					});
				}

			}, 3000);

		},
		cancelPing (){
			this.$store.commit("cancelSmartPing");
			//this.$store.commit("loginSmartUser");
		},
		//login

		registerPingSmartId () {
			this.ping = setInterval(() => {
				if(this.smartPing){
					this.$store.dispatch("registerPingSmart");
				}
				else{
					clearInterval(this.ping);
					this.cancelPing();
				}
				if(this.smartError && this.smartErrors !== ""){
					if(this.$te("smart_id_login." + this.smartErrors)){
						this.$store.commit("changeError",
							("smart_id_login." + this.smartErrors));
						this.$store.commit("showError",true);
					}
					else{
						this.$store.commit("changeError",
							("repeating_errors." + this.smartErrors));
						this.$store.commit("showError",true);
					}
				}
				else if(this.smartUser){
					this.$router.push({
						name: "setupAccount",
						params: {
							user: this.smartUser,
						},
					});
				}

			}, 3000);
		},

		//register
		//api functionality
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
