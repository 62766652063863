<template>
	<v-footer
		:padless="true"
		class="footer pt-4 pt-sm-0">
		<span
			class="subhead-2 footer__link footer__link--project-name"
			:class="$vuetify.theme.dark ? 'whitebase--text' : 'graybase--text'">&copy; {{ project.name }}. {{ $t('footer.all_rights_reserved') }}.</span>
		<a
			v-for="footerItem in linksCombined"
			:key="footerItem.name"
			:href="footerItem.link"
			target="_blank"
			class="subhead-2 footer__link"
			:class="$vuetify.theme.dark ? 'whitebase--text' : 'graybase--text'">
			{{ $t(footerItem.name) }}
		</a>
	</v-footer>
</template>

<script>
import { getEnv } from "../env"

export default {
	data (){
		return{
			appCountry: getEnv('VUE_APP_FRONTEND_COUNTRY'),
			project: { name : getEnv('VUE_APP_FRONTEND_PROJECT_NAME'), link : "#" },
			footerItems: [
				{ name: "footer.about", link: getEnv('VUE_APP_FRONTEND_FOOTER_ABOUT') },
			],
			contactFormUrl: getEnv('VUE_APP_FRONTEND_CONTACT_FORM_URL'),
		}
	},
	computed:{
		computedLinks () {
			return [
				{ name: "footer.contacts", link: this.getContactFormUrl(this.$i18n.locale) },
				{ name: "footer.terms", link : this.$i18n.locale == 'et' ? 'https://www.ekool.eu/teenusetingimused' : 'https://www.ekool.eu/en/terms-of-service' },
			]
		},
		linksCombined () {
			if(getEnv('VUE_APP_FRONTEND_COUNTRY') == 'EU' && getEnv('VUE_APP_FRONTEND_PROJECT_NAME') == 'eKool'){
				return [...this.footerItems, ...this.computedLinks]
			}
			else{
				return this.footerItems
			}
		},
	},
	mounted (){
		if(this.project.name == 'Mykoob'){
			this.project.link = "https://login.mykoob.lv/login/";
			this.footerItems = [
				{ name: "terms.terms_of_service", link: getEnv('VUE_APP_FRONTEND_APP_URL') + '/#/lv/terms/service' },
				{ name: "terms.privacy_policy", link: getEnv('VUE_APP_FRONTEND_APP_URL') + '/#/lv/terms/privacy-policy' },
				{ name: "footer.support", link: "https://mykoob.freshdesk.com/support/home" },
				{ name: "footer.schoolList", link: "https://www.mykoob.lv/?index/skolu_saraksts" },
				{ name: "footer.contacts", link: "https://mykoob.freshdesk.com/support/tickets/new" },
			]
		}
	},
	methods:{
		getContactFormUrl (locale) {
			switch(locale){
			case 'en':
				return this.contactFormUrl + '/en_US'
			case 'ru':
				return this.contactFormUrl + '/ru_RU'
			default:
				return this.contactFormUrl
			}
		},
	},
}
</script>
