
<template>
	<div class="view-content__wrapper">
		<div
			class="view-content mx-auto"
			:class="{'setup-container': currentStep.order == 1}">
			<div class="d-flex justify-space-between align-center mb-6">
				<div class="headline-1">
					{{ $t("setup_account." + currentStep.name) }}
				</div>
				<div>
					<e-stepper-indicator
						:value="currentStep.order"
						:steps="registrationSteps.length">
						<span :class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">{{ currentStep.order }} / {{ registrationSteps.length }}</span>
					</e-stepper-indicator>
				</div>
			</div>
			<transition
				name="fade"
				mode="out-in">
				<div
					v-if="currentStep.order == 1"
					key="account"
					class="mx-auto">
					<Alert v-if="getError" />
					<ValidationObserver
						v-slot="{handleSubmit,invalid}"
						ref="firstFormValidationObserver"
						slim>
						<form @submit.prevent="handleSubmit(forward)">
							<ValidationProvider
								ref="emailValidationProvider"
								v-slot="{errors}"
								name="email"
								rules="required|email"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="email"
										large=""
										:success="registerEmailValidateSuccess"
										:disabled="disabledXid || disabledThirdParty || disabledTeacherInvite"
										:label="$i18n.t('setup_account.email')"
										:error="(errors[0] && errors[0] !== '') || (registerEmailValidateError && registerEmailValidateErrors !== '')"
										:error-messages="errors[0] ? errors[0] : (registerEmailValidateErrors ? $t(`setup_account.${registerEmailValidateErrors}`) : '')"
										@input="handleEmailInput()" />
								</div>
							</ValidationProvider>
							<div class="pt-2 pb-4">
								<div
									class="phone-container"
									:class="{'pb-2': phoneNumberError || phoneNumberValidationError}">
									<ValidationProvider
										rules="required"
										name="code"
										:mode="validateOnInput"
										slim>
										<div
											class="pt-4 mr-2 phone-container__code--registration"
											style="max-width: 12.4rem;">
											<e-select
												v-model="code"
												large=""
												class="body-2 country-code-input"
												:error="phoneNumberError && !hidePhoneNumberError"
												:value="code"
												:items="countries"
												item-text="Name"
												item-value="MobileCode">
												<!-- Label -->
												<template #label>
													<div class="country-code-label">
														{{ $t('setup_account.phone_number_optional') }}
													</div>
												</template>
												<!-- inner icon -->
												<template
													#prepend-inner>
													<flag
														:squared="false"
														:iso="getCodeByMobileCode(code)" />
												</template>
												<!-- modify selected item -->
												<template #selection="{item}">
													{{ item.MobileCode }}
												</template>
												<!-- modify list items -->
												<template
													#item="{props, item}">
													<div>
														<flag
															:squared="false"
															v-bind="props"
															:iso="item.Code" />
														<span
															v-bind="props"
															class="ml-2">{{ item.Name }}</span>
														<span
															v-bind="props"
															class="ml-2">{{ item.MobileCode }}</span>
													</div>
												</template>
											</e-select>	
										</div>
									</ValidationProvider>
									<ValidationProvider
										ref="phoneNumberValidationProvider"
										v-slot="{errors}"
										name="phone"
										rules="numeric"
										:mode="validateOnInput"
										slim>
										<div class="pt-4 phone-container__number flex">
											<e-text-field
												v-model="number"
												large=""
												class="display-inline"
												:error="(errors[0] && errors[0] !== '') || (phoneNumberError && !hidePhoneNumberError)"
												@focusout="handlePhoneNumberInput()"
												@input.native="handlePhoneNumberInput($event.target.value)">
												<template
													#append
													v-if="registerPhoneVerifyRequestSuccess && registerPhoneVerifyCodeSuccess">
													<v-icon>
														icon-success success200--text
													</v-icon>
												</template>
											</e-text-field>
										</div>
									</ValidationProvider>
								</div>
								<span
									v-if="(phoneNumberError || phoneNumberValidationError) && !hidePhoneNumberError"
									class="caption-1 danger200--text mt-2">{{ phoneNumberErrorMessage }}</span>
							</div>
							<ValidationProvider
								v-slot="{errors}"
								name="password"
								rules="required|passwordStrength|min:9"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="password"
										large=""
										:label="$i18n.t('setup_account.password')"
										:type="showPassword1 ? 'text' : 'password'"
										:appendIcon="showPassword1 ? 'icon-eye-cut' : 'icon-eye'"
										:error="errors[0] && errors[0] !== ''"
										:error-messages="errors[0]"
										@click:append="showPassword1 = !showPassword1" />
								</div>
							</ValidationProvider>
							<ValidationProvider
								v-slot="{errors}"
								name="repeatPassword"
								rules="required|password:@password"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="repeatPassword"
										large=""
										:label="$i18n.t('setup_account.repeat_password')"
										:type="showPassword2 ? 'text' : 'password'"
										:appendIcon="showPassword2 ? 'icon-eye-cut' : 'icon-eye'"
										:error="errors[0] && errors[0] !== ''"
										:error-messages="errors[0]"
										@click:append="showPassword2 = !showPassword2" />
									<p class="body-3 grayscale400--text pt-2 mb-0">
										* {{ $t("setup_account.password_hint") }}
									</p>
								</div>
							</ValidationProvider>
							<div>
								<e-checkbox
									v-model="offers"
									class="body-3"
									:label="$i18n.t('setup_account.recieve_offers')" />
							</div>
							<e-button
								class="mt-4"
								:block="true"
								:disabled="(invalid || registerEmailValidateError || (timeLeft > 0 && !registerPhoneVerifyRequestSuccess && !registerPhoneVerifyCodeSuccess)) && number !== ''"
								:loading="showModal || registerPhoneVerifyRequestBusy"
								type="submit"
								variant="primary"
								size="large"
								large>
								{{ phoneNumberError ? $t('setup_account.try_again') : $t('setup_account.forward') }}
							</e-button>
							<p class="body-3 text-center mt-2">
								<span class="grayscale400--text">{{ $t("setup_account.terms_text_1") }} </span>
								<a
									class="text-decoration-none primary--text"
									:href="terms.main"
									target="_blank">{{ $t("setup_account.terms_link_1") }} </a>
								<span class="grayscale400--text">{{ $t("setup_account.terms_text_2") }} </span>
								<a
									class="text-decoration-none primary--text"
									:href="terms.data + $i18n.locale + '.html'"
									target="_blank">{{ $t("setup_account.terms_link_2") }}</a>
							</p>
							<div
								v-show="showCFTurnstileWidget"
								class="turnstile-widget mt-4"
								:class="{'d-flex': showCFTurnstileWidget}" />
						</form>
					</ValidationObserver>
				</div>
				<div
					v-if="currentStep.order == 2"
					key="personal"
					class="personal-data">
					<ValidationObserver
						v-slot="{handleSubmit}"
						ref="secondFormValidationObserver"
						slim>
						<form
							@submit.prevent="handleSubmit(account)">
							<ValidationProvider
								v-slot="{errors}"
								name="firstName"
								rules="required|max:255"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="firstName"
										large=""
										:disabled="disabled || disabledXid"
										:label="$i18n.t('setup_account.first_name')"
										:error="errors[0] && errors[0] !== ''"
										:error-messages="errors[0]" />
								</div>
							</ValidationProvider>
							<ValidationProvider
								v-slot="{errors}"
								name="lastName"
								rules="required|max:255"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="lastName"
										large=""
										:disabled="disabled || disabledXid"
										:label="$i18n.t('setup_account.last_name')"
										:error="errors[0] && errors[0] !== ''"
										:error-messages="errors[0]" />
								</div>
							</ValidationProvider>
							<ValidationProvider
								v-slot="{errors}"
								name="id-code"
								:rules="{required: true, handleIDCode: issuer == 'EE', max: 255}"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="idCode"
										large=""
										:disabled="disabled"
										:label="$i18n.t('setup_account.id_code')"
										:error="errors[0] && errors[0] !== ''"
										:error-messages="errors[0]" />
								</div>
							</ValidationProvider>
							<ValidationProvider
								v-slot="{errors}"
								name="issuer"
								rules="required"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-select
										v-model="issuer"
										large=""
										class="body-2"
										:disabled="disabled"
										:error="errors[0] && errors[0] !== ''"
										:errorMessages="errors[0]"
										:label="$i18n.t('setup_account.select_issuer')"
										:items="countries"
										:placeholder="$i18n.t('setup_account.select')"
										item-text="Name"
										item-value="Code" />
								</div>
							</ValidationProvider>
							
							<div class="py-4">
								<v-menu
									ref="menu"
									:disabled="disabledXid"
									:value="formattedDates"
									max-width="294"
									nudge-top="365px"
									nudge-right="40px"
									:close-on-content-click="false">
									<template #activator="{on}">
										<ValidationProvider
											v-slot="{errors}"
											name="date"
											:rules="{required: true, regex: /^\d{1,2}\.\d{1,2}\.\d{4}$/, dateOfBirth, checkDate: {idCode: '@id-code',issuer: '@issuer'}}"
											:mode="validateOnInput"
											slim>
											<e-text-field
												v-model="formattedDate"
												large=""
												appendIcon="icon-calendar"
												:disabled="disabledXid"
												:label="$i18n.t('setup_account.date_of_birth')"
												:placeholder="$t('setup_account.date_placeholder')"
												:error="errors[0] && errors[0] !== ''"
												:error-messages="errors[0]"
												v-on="on"
												@input="dateTyped" />
										</ValidationProvider>
									</template>
									<e-date-picker
										v-model="date"
										:locale="$i18n.locale"
										:show-current="false"
										:max="maxDate"
										width="294"
										@input="datePicked" />
								</v-menu>
							</div>

							<ValidationProvider
								v-slot="{errors}"
								name="gender"
								:mode="validateOnInput"
								rules="required|checkGender:@id-code,@issuer">
								<div class="py-4">
									<e-select
										v-model="gender"
										large=""
										class="body-2"
										:disabled="disabledXid"
										:error="errors[0] && errors[0] !== ''"
										:errorMessages="errors[0]"
										:label="$i18n.t('setup_account.gender')"
										:items="genders"
										:placeholder="$i18n.t('setup_account.select')"
										item-value="value"
										item-text="name">
										<template
											#item="{props, item}">
											<span v-bind="props">{{ $i18n.t(item.name) }}</span>
										</template>
										<template #selection="{item}">
											{{ $i18n.t(item.name) }}
										</template>
									</e-select>	
								</div>
							</validationprovider>
							<div class="d-flex justify-space-between align-center mt-2">
								<e-button
									small
									variant="link"
									@click="back()">
									{{ $t('setup_account.go_back') }}
								</e-button>
								<e-button
									type="submit"
									variant="primary"
									size="large"
									large>
									{{ $t('setup_account.forward') }}
								</e-button>
							</div>
						</form>
					</ValidationObserver>
				</div>
				<div
					v-if="currentStep.order == 3"
					key="address"
					class="personal-data">
					<ValidationObserver
						v-slot="{handleSubmit}"
						ref="thirdFormValidationObserver"
						slim>
						<form
							@submit.prevent="handleSubmit(address)">
							<ValidationProvider
								rules="required"
								name="country"
								:mode="validateOnInput"
								slim>
								<div
									class="py-4">
									<e-select
										v-model="country"
										large=""
										class="body-2"
										:error="phoneNumberError && !hidePhoneNumberError"
										:value="country"
										:items="countries"
										item-text="Code"
										item-value="Code">
										<!-- Label -->
										<template #label>
											<div class="country-code-label">
												{{ $t('setup_account.country') }}
											</div>
										</template>
										<!-- inner icon -->
										<template
											#prepend-inner>
											<flag
												:squared="false"
												:iso="country" />
										</template>
										<!-- modify selected item -->
										<template #selection="{item}">
											{{ item.Name }}
										</template>
										<!-- modify list items -->
										<template
											#item="{props, item}">
											<div>
												<flag
													:squared="false"
													v-bind="props"
													:iso="item.Code" />
												<span
													v-bind="props"
													class="ml-2">{{ item.Name }}
												</span>
											</div>
										</template>
									</e-select>	
								</div>
							</ValidationProvider>
							<ValidationProvider
								v-slot="{errors}"
								name="county"
								rules="max:255"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="county"
										large=""
										:label="$i18n.t('school_registration.county')"
										:error="errors[0] && errors[0] !== ''"
										:error-messages="errors[0]" />
								</div>
							</ValidationProvider>
							<ValidationProvider
								v-slot="{errors}"
								name="street"
								rules="required|max:255"
								:mode="validateOnInput"
								slim>
								<div class="py-4">
									<e-text-field
										v-model="street"
										large=""
										:disabled="disabledXid"
										:label="$i18n.t('setup_account.street')"
										:error="errors[0] && errors[0] !== ''"
										:error-messages="errors[0]" />
								</div>
							</ValidationProvider>
							<div class="city-postal-index-container">
								<ValidationProvider
									v-slot="{errors}"
									name="city"
									rules="required|max:255"
									:mode="validateOnInput"
									slim>
									<div class="py-4">
										<e-text-field
											v-model="city"
											large=""
											:disabled="disabledXid"
											:label="$i18n.t('setup_account.city')"
											:error="errors[0] && errors[0] !== ''"
											:error-messages="errors[0]" />
									</div>
								</ValidationProvider>
								<ValidationProvider
									v-slot="{errors}"
									name="postalIndex"
									rules="required|max:255"
									:mode="validateOnInput"
									slim>
									<div class="py-4">
										<e-text-field
											v-model="postalIndex"
											large=""
											:label="$i18n.t('setup_account.postal_index')"
											:error="errors[0] && errors[0] !== ''"
											:error-messages="errors[0]" />
									</div>
								</ValidationProvider>
							</div>
							<div class="d-flex justify-space-between align-center mt-2">
								<e-button
									small
									variant="link"
									@click="back()">
									{{ $t('setup_account.go_back') }}
								</e-button>
								<e-button
									type="submit"
									variant="primary"
									size="large"
									large>
									{{ $t('setup_account.create_account') }}
								</e-button>
							</div>
						</form>
					</ValidationObserver>
				</div>
			</transition>
		</div>
		<VerifyPhoneNumberModal
			v-if="showPhoneNumberModal"
			:phoneNumber="number"
			:countryCode="code" />
	</div>
</template>

<script>
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Alert from "@/components/Alert.vue";
import VerifyPhoneNumberModal from "@/components/VerifyPhoneNumberModal.vue";
import { countries } from "../utils/countries";
import { mapGetters } from 'vuex'
import { validate } from 'vee-validate';
import { getEnv } from "../env";
export default {
	components: {
		Alert,
		VerifyPhoneNumberModal,
	},
	data () {
		return {
			firstName: "",
			lastName: "",
			disabled: false,
			disabledXid: false,
			idCode: "",
			issuer: "EE",
			dateOfBirth: "",
			gender: "",
			email: "",
			repeatEmail: "",
			password: "",
			repeatPassword: "",
			offers: false,
			errors: {
				wrongDate: false,
				wrongIdCode: false,
				repeatEmail: false,
			},
			countries: countries,
			currentStep: { order: 1, name: "personal_data" },
			registrationSteps: [
				{ order: 1, name: "personal_data" },
				{ order: 2, name: "personal_data" },
				{ order: 3, name: "address" },
			],
			genders: [
				{ value: 0, name: 'setup_account.male' },
				{ value: 1, name: 'setup_account.female' },
			],
			showPassword1: false,
			showPassword2: false,
			mobilePrefixes: ["+372","+370","+371"],
			number: undefined,
			code: "+372",
			revalidate: false,
			phoneNumberValidationError: "",
			timeLeft: 0,
			timeoutTimer: false,
			hidePhoneNumberError: false,
			formattedDate: "",
			date: "",
			maxDate: format(new Date(), 'yyyy-MM-dd'),
			token: "",
			country: "EE",
			county: null,
			city: null,
			street: null,
			postalIndex: null,
			showCFTurnstileWidget: false,
			showCFTurnstileWidgetTimeout: null,
			thirdPartyHash: null,
			disabledThirdParty: false,
			disabledTeacherInvite: false,
		};
	},
	computed: {
		terms (){
			return {
				main: this.$i18n.locale == 'et' ? 'https://www.ekool.eu/teenusetingimused' : 'https://www.ekool.eu/en/terms-of-service',
				data: getEnv('VUE_APP_FRONTEND_TERMS_DATA'),
			}
		},
		...mapGetters([
			'setupAccountBusy',
			'setupAccountSuccess',
			'setupAccountError',
			'setupAccountErrors',
			'setupAccountMessage',
			'setupAccountCountry',
			'setupAccountHash',
			'getError',
			'showPhoneNumberModal',
			'registerEmailValidateBusy',
			'registerEmailValidateSuccess',
			'registerEmailValidateError',
			'registerEmailValidateErrors',
			'registerPhoneVerifyRequestBusy',
			'registerPhoneVerifyRequestSuccess',
			'registerPhoneVerifyRequestError',
			'registerPhoneVerifyRequestErrors',
			'registerPhoneVerifyRequestTimeout',
			'registerPhoneVerifyCodeBusy',
			'registerPhoneVerifyCodeSuccess',
			'registerPhoneVerifyCodeError',
			'registerPhoneVerifyCodeErrors',
			'CFTurnstileToken',
			'CFTurnstileWidgetId',
			'CFTurnstileScriptLoaded',
		]),
		showModal: {
			get () {
				return this.showPhoneNumberModal
			},
			set (value) {
				this.$store.commit('setPhoneNumberModal', value)
			},
		},
		phoneNumberError (){
			return this.registerPhoneVerifyRequestError || this.registerPhoneVerifyCodeError
		},
		phoneNumberErrorMessage (){
			if(this.phoneNumberValidationError !== '' && this.phoneNumberValidationError !== undefined){
				return this.phoneNumberValidationError
			}
			else if(this.registerPhoneVerifyRequestError){
				return this.registerPhoneVerifyRequestErrors == 'too_many_requests' ? this.$t(`setup_account.${this.registerPhoneVerifyRequestErrors}`, { x : this.timeLeft }) : this.$t(`setup_account.${this.registerPhoneVerifyRequestErrors}`)
			}
			else if(this.registerPhoneVerifyCodeError){
				return this.$t(`setup_account.${this.registerPhoneVerifyCodeErrors}`)
			}
			else{
				return false
			}
		},
	},
	watch: {
		idCode (){
			if(this.issuer == 'EE'){
				validate (this.idCode, 'handleIDCode', {
					name: 'id-code',
				}).then(result => {
					if (result.valid && this.idCode !== "") {
						this.fillGenderIdCode();
					}else{
						this.formattedDate = "";
						this.gender = "";
					}
				});
			}
			else{
				return false
			}
		},
		issuer (){
			if(this.idCode !== ""){
				this.handleIdCode();
				this.fillGenderIdCode();
			}
			else{
				return false
			}
		},
		code (){
			//watch if changed after validation, revalidate again on submit
			if(this.registerPhoneVerifyCodeSuccess){
				this.revalidate = true
			}
		},
		number (val){
			//watch if changed after validation, revalidate again on submit
			if(this.registerPhoneVerifyCodeSuccess){
				this.revalidate = true
			}
			if(val == ""){
				this.$store.commit("clearPhoneNumberErrors"); // clear errors
				this.timeLeft = 0; // clear timeout
			}
		},
		registerPhoneVerifyCodeSuccess (val){
			if(val){
				this.currentStep = this.registrationSteps.find(el => el.order == this.currentStep.order + 1);
				this.revalidate = false
			}
		},
		registerPhoneVerifyRequestTimeout (){
			this.countdown()
		},
		timeLeft (){
			if(this.registerPhoneVerifyRequestTimeout !== 0 && this.timeLeft == 0){
				this.hidePhoneNumberError = true
			}
		},
		CFTurnstileScriptLoaded (val) {
			if(val){
				this.initCFTurnstile();
			}
		},
	},
	mounted () {
		if (this.$route.params.user) {
			this.firstName = this.$route.params.user.name;
			this.lastName = this.$route.params.user.surname;
			if(this.$route.params.user.personal_code){
				this.idCode = this.$route.params.user.personal_code;
			}
			if(this.$route.params.user.iso){
				this.issuer = this.$route.params.user.iso;
			}
			//additional fields for x-id registration
			if(this.$route.params.user.date_of_birth){
				this.formattedDate = this.$route.params.user.date_of_birth;
			}
			if(this.$route.params.user.gender){
				this.gender = this.$route.params.user.gender;
			}
			this.email = this.$route.params.user.email;
			//check to disable har data fields or other 3rd party data fields
			if(this.$route.params.user.third_party_hash){
				this.thirdPartyHash = this.$route.params.user.third_party_hash;
				this.disabledThirdParty = true;
			}
			else if(Object.keys(this.$route.params.user).length == 7){
				this.disabledXid = true;
			}
			else if(Object.keys(this.$route.params.user).length == 1){
				this.disabledTeacherInvite = true;
			}
			else{
				this.disabled = true;
			}
		}
		if(this.CFTurnstileScriptLoaded){
			this.initCFTurnstile();
		}
	},
	beforeDestroy () {
		/* eslint-disable no-undef */
		turnstile.remove(this.CFTurnstileWidgetId)
		if(this.showCFTurnstileWidgetTimeout){
			clearTimeout(this.showCFTurnstileWidgetTimeout);
			this.showCFTurnstileWidget = false;
		}
	},
	methods: {
		forward () {
			this.$refs['firstFormValidationObserver'].validate() //validate first form on submit
			if((!this.registerPhoneVerifyCodeSuccess || this.revalidate) && this.number){
				this.registerPhoneVerifyRequest()
			}
			else{
				//clear arr phone number errors
				this.$store.commit("clearPhoneNumberErrors");
				this.currentStep = this.registrationSteps.find(el => el.order == this.currentStep.order + 1);
			}
		},
		back () {
			this.currentStep = this.registrationSteps.find(el => el.order == this.currentStep.order - 1);
		},
		account () {
			this.$refs['secondFormValidationObserver'].validate().then(() => {
				this.currentStep = this.registrationSteps.find(el => el.order == this.currentStep.order + 1);
			})
		},
		address () {
			this.$refs['thirdFormValidationObserver'].validate() //validate third form on submit
			var firstName = this.firstName;
			var lastName = this.lastName;
			var idCode = this.idCode;
			var issuer = this.issuer;
			var dateOfBirth = this.formattedDate;
			var gender = this.gender;
			var email = this.email;
			var emailConfirmation = this.email;
			var password = this.password;
			var passwordConfirmation = this.repeatPassword;
			var wishPartnerAds = this.offers;
			var countryCode = (this.number == undefined || this.number == "") ? "" : this.code; // if no number, set country code to empty string
			var phoneNumber = this.number;
			var country = this.country;
			var county = this.county;
			var city = this.city;
			var street = this.street;
			var postalIndex = this.postalIndex;
			if(this.thirdPartyHash){
				var thirdPartyHash = this.thirdPartyHash;
			}
			//teacher invite extra params
			if(this.$route.params.inviteType){
				var inviteType = this.$route.params.inviteType;
			}
			if(this.$route.params.inviteHash){
				var inviteHash = this.$route.params.inviteHash;
			}
			this.$store.dispatch('setupAccount', { firstName,
				lastName,
				idCode,
				issuer,
				dateOfBirth,
				gender,
				email,
				emailConfirmation,
				password,
				passwordConfirmation,
				wishPartnerAds,
				countryCode,
				phoneNumber,
				country,
				county,
				city,
				street,
				postalIndex,
				thirdPartyHash,
				inviteType,
				inviteHash }).then(
				() => {
					if(this.setupAccountSuccess && this.thirdPartyHash && (this.setupAccountMessage == '' || !this.setupAccountMessage)){
						this.$router.push({
							name: "welcome",
						});
					}
					else if(this.setupAccountSuccess && this.setupAccountMessage == 'user_id_code_already_exist'){
						this.$router.push({
							name: "message",
							params: {
								thirdParty: this.thirdPartyHash ? true : false,
								type: "registrationHash",
							},
						});
					}
					else if(this.setupAccountSuccess && this.setupAccountMessage){
						this.$router.push({
							name: "success",
							params: {
								//email: this.email,
								type: "registrationMessage",
								message: this.setupAccountMessage,
							},
						});
					}
					else if(this.setupAccountSuccess){
						this.$router.push({
							name: "success",
							params: {
								email: this.email,
								type: "registration",
							},
						});
					}
					else if(this.setupAccountError && this.setupAccountErrors){
						if(this.$te("registration_failed." + this.setupAccountErrors)){
							this.$store.commit("changeError",
								("registration_failed." + this.setupAccountErrors));
							this.$store.commit("showError",true);
						}
						else{
							this.$store.commit("changeError",
								("repeating_errors." + this.setupAccountErrors));
							this.$store.commit("showError",true);
						}
					}
				},

			)
		},
		handleIdCode (){
			var idCodeArr = Array.from(this.idCode).map(Number);
			if (idCodeArr.length !== 11) {
				return false;
			}
			var wages1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
			var sum1 = 0;
			for (var i = 0; i !== 10; i++) {
				sum1 += idCodeArr[i] * wages1[i];
			}
			var module1 = sum1 % 11;
			if (module1 !== 10) {
				return module1 === idCodeArr[10];
			}
			var wages2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];
			var sum2 = 0;
			for (var j = 0; j !== 10; j++) {
				sum2 += idCodeArr[j] * wages2[j];
			}
			var module2 = sum2 % 11;
			if (module2 !== 10) {
				return module2 === idCodeArr[10];
			}
			return idCodeArr[10] === 0;
		},
		fillGenderIdCode (){
			if(this.issuer == 'EE'){
				//date of birth stuff
				var idCodeArr = Array.from(this.idCode).map(Number);
				var year;
				if (idCodeArr[0] === 1 || idCodeArr[0] === 2) {
					year = "18";
				} else if (idCodeArr[0] === 3 || idCodeArr[0] === 4) {
					year = "19";
				} else if (idCodeArr[0] === 5 || idCodeArr[0] === 6) {
					year = "20";
				}
				var correctYear =
				idCodeArr[5] +
				"" +
				idCodeArr[6] +
				"." +
				idCodeArr[3] +
				"" +
				idCodeArr[4] +
				"." +
				year +
				"" +
				idCodeArr[1] +
				"" +
				idCodeArr[2];
				this.formattedDate = correctYear;
				//gender stuff
				if (idCodeArr[0] % 2 === 0) {
				//Female
					this.gender = 1;
				} else {
				//Male
					this.gender = 0;
				}
			}
		},
		validateEmail (){
			let email = this.email;
			this.$store.dispatch('registerEmailValidate', { email })
		},
		async registerPhoneVerifyRequest (){
			//clear timeout
			clearInterval(this.timeoutTimer);
			this.hidePhoneNumberError = false;
			let countryCode = this.code
			let phoneNumber = this.number
			let token = this.CFTurnstileToken
			this.$store.dispatch('registerPhoneVerifyRequest', { countryCode, phoneNumber, token }).then(
				() => {
					/* eslint-disable no-undef */
					turnstile.reset(this.CFTurnstileWidgetId)
					if(this.registerPhoneVerifyRequestSuccess){
						this.showModal = true
					}
				},
			)
		},
		handleEmailInput (){
			this.$refs['emailValidationProvider'].validate().then( () => {
				if(this.$refs['emailValidationProvider'].flags.valid){
					this.validateEmail()
				}
			})
		},
		handlePhoneNumberInput (value){
			this.$refs['phoneNumberValidationProvider'].validate().then( () => {
				this.phoneNumberValidationError = this.$refs['phoneNumberValidationProvider'].errors[0]
			})

			if(value !== undefined && this.registerPhoneVerifyCodeSuccess){
				this.$store.commit('setRegisterPhoneVerifyCodeSuccess', false)
			}
		},
		countdown (){
			clearInterval(this.timeoutTimer);
			this.timeLeft = this.registerPhoneVerifyRequestTimeout
			this.timeoutTimer = setInterval(() => {
				if(this.timeLeft == 0){
					clearInterval(this.timeoutTimer);
				}
				else{
					this.timeLeft -= 1;
				}
			}, 1000);
		},
		getCodeByMobileCode (mobileCode){
			const foundCountry = this.countries.find(country => country.MobileCode === mobileCode);
			return foundCountry ? foundCountry.Code : null;
		},
		datePicked (date) {
			if (!date) return;
			this.formattedDate = this.formatDateForInput(date);
		},
		formatDateForInput (date) {
			return format(parseISO(date), 'dd.MM.yyyy');
			
		},
		dateTyped (date) {
			if (!date || date.length !== 10) return;
			this.date = this.formatDateForPicker(date);
		},
		formatDateForPicker (date) {
			const [day, month, year] = date.split(".");
			return `${year}-${month}-${day}`;
		},
		validateOnInput () {
			return {
				on: ['input'],
			}
		},
		initCFTurnstile (){
			this.$store.commit("setDisableButton",true);
			/* eslint-disable no-undef */
			let widgetId = turnstile.render('.turnstile-widget', {
				sitekey: getEnv('VUE_APP_FRONTEND_CF_TURNSTILE_SITE_KEY'),
				theme: this.$vuetify.theme.dark ? 'dark' : 'light',
				callback: (token) => {
					this.$store.commit("setCFTurnstileToken",token);
					this.$store.commit("setDisableButton",false);
					this.showCFTurnstileWidget = false;
				},
				'error-callback': () => {
					if(this.mobileView){
						this.$store.commit("setDisableButton",false);
					}
					this.showCFTurnstileWidgetTimeout = setTimeout(() => this.showCFTurnstileWidget = true,5000);
				},
			});
			this.$store.commit("setCFTurnstileWidgetId",widgetId);
			//reset CF turnstile every 295 seconds
			setInterval(() => {
				turnstile.reset(this.CFTurnstileWidgetId);
			}, 295000)
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
