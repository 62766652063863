<template>
	<div class="d-flex flex-column align-center">
		<div class="d-flex flex-column align-center">
			<span
				v-if="method == 'otp'"
				class="body-2 text-center"
				:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
				{{ $t('two_factor.otp_description') }}
			</span>
			<i18n
				v-else-if="method == 'email'"
				path="two_factor.email_description"
				tag="span"
				class="body-2 text-center"
				:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
				<template #email>
					<span :class="$vuetify.theme.dark ? 'grayscale200--text' : 'grayscale800--text'">{{ twoFactorRequestEmail }}</span>
				</template>
				<template #x>
					{{ 5 }}
				</template>
			</i18n>
			<i18n
				v-else-if="method == 'sms'"
				path="setup_account.code_description"
				tag="span"
				class="body-2 text-center"
				:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
				<template #phone>
					<span :class="$vuetify.theme.dark ? 'grayscale200--text' : 'grayscale800--text'">{{ twoFactorRequestPhoneNumber }}</span>
				</template>
				<template #x>
					{{ 5 }}
				</template>
			</i18n>
			<span
				v-else-if="method == 'recovery'"
				class="body-2 text-center"
				:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
				{{ $t('two_factor.recovery_description') }}
			</span>
		</div>
		<div
			class="d-flex flex-column align-center my-8"
			style="width: 100%;">
			<div
				v-if="!twoFactorBusy"
				id="otp-container"
				class="d-flex"
				:class="{'mb-3': method !== 'otp' && method !== 'recovery'}">
				<e-text-field
					v-for="(otpInput, index) in otpInputs"
					:key="index"
					ref="otpInput"
					v-model="otpInputs[index]"
					class="otp-input"
					:class="'otp-input--' + index"
					large=""
					maxlength="6"
					:inputmode="getInputMode()"
					autocomplete="one-time-code"
					@input.native="onInput(index, $event.target.value, $event)"
					@keydown.native="onKeyDown(index, $event)"
					@paste.native="onPaste(index, $event)" />
			</div>
			<e-circular-loading
				v-if="twoFactorBusy"
				:class="{'mb-3': method !== 'otp' && method !== 'recovery'}" />
			<template v-if="method !== 'otp' && method !== 'recovery'">
				<div
					v-if="requestNew"
					class="d-flex justify-content-center">
					<span class="caption-1 grayscale400--text">{{ $t('setup_account.request_new_code', {x: timeLeft}) }}</span>
				</div>
				<div
					v-else
					class="d-flex justify-content-center">
					<span class="caption-1 grayscale400--text mr-1">{{ $t('setup_account.didnt_get_code') }}</span>
					<span
						class="caption-1 primary400--text"
						style="cursor: pointer;"
						@click="resendCode()">{{ $t('setup_account.resend_code') }}</span>
				</div>
			</template>	
		</div>
		<e-button
			variant="tertiary"
			@click="cancel">
			{{ $t('terms.cancel') }}
		</e-button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getEnv } from '../env';
export default {
	props:{
		method: String,
	},
	data () {
		return {
			otpInputs: Array(6).fill(''), // Adjust the length as needed
			requestNew: false,
			timeLeft: 0,
			timeoutTimer: false,
			recaptcha: {
				recaptcha: "",
				siteKey: getEnv('VUE_APP_FRONTEND_RECAPTCHA_SITE_KEY'),
			},
		};
	},
	computed: {
		...mapGetters([
			'iframeView',
			'twoFactorAvailableMethods',
			'twoFactorRequestBusy',
			'twoFactorRequestSuccess',
			'twoFactorRequestError',
			'twoFactorRequestErrors',
			'twoFactorRequestTimeout',
			'twoFactorRequestEmail',
			'twoFactorRequestPhoneNumber',
			'twoFactorBusy',
			'twoFactorError',
			'twoFactorErrors',
			'twoFactorSuccess',
			'termsRequired',
			'rememberRequestRequired',
		]),
	},
	watch: {
		twoFactorRequestTimeout (){
			this.countdown()
		},
	},
	mounted (){
		if(this.method == 'email' || this.method == 'sms' && this.twoFactorAvailableMethods.length == 1){
			this.resendCode();
		}
		this.requestNew = false;
		this.countdown();
	},
	methods: {
		onInput (index, value, event) {
			// Allow only digits (0-9)
			const digit = value.trim();
			const regex = this.method === 'recovery' ? /^[a-z0-9]+$/ : /^\d$/
			// Workaround for mobile chrome not firing paste event on sms auto-populate
			if(digit.length > 1){
				event.clipboardData = {
					getData: () => digit,
				}
				this.onPaste(0,event)
			}
			if (regex.test(digit)) {
				this.$set(this.otpInputs, index, digit);
				if (index < this.otpInputs.length - 1 && digit) {
					this.focusNextInput(index);
				}
			} else {
				// Clear the input if it's not a digit
				event.preventDefault()
				this.$set(this.otpInputs, index, '');
			}
			// Check if all fields are filled
			if (this.isOTPComplete()) {
				this.sendTwoFactor();
			}
		},
		onKeyDown (index, event) {
			const key = event.keyCode || event.which;

			if (key === 8 && index > 0 && !this.otpInputs[index]) {
				// Handle backspace when the input is empty
				event.preventDefault();
				this.focusPreviousInput(index);
			} else if (key === 8) {
				// Clear the current input on backspace
				event.preventDefault();
				this.$set(this.otpInputs, index, '');
			} else if (key === 37 && index > 0) {
				// Move left
				event.preventDefault();
				this.focusPreviousInput(index);
			} else if (key === 39 && index < this.otpInputs.length - 1) {
				// Move right
				event.preventDefault();
				this.focusNextInput(index);
			} else if (key === 13 || (key === 9 && index === this.otpInputs.length - 1)) {
				// Enter or Tab key (on last input)
				event.preventDefault();
				this.sendTwoFactor();
			}
		},
		onPaste (index, event) {
			event.preventDefault();
			const clipboardData = event.clipboardData || window.clipboardData;
			let pastedData = clipboardData.getData('Text');
			const startIndex = Math.min(index, this.otpInputs.length - 1);
			const regex = this.method === 'recovery' ? /^[a-z0-9]+$/ : /^\d$/

			for (let i = startIndex; i < this.otpInputs.length; i++) {
				if (pastedData.length > 0) {
					const char = pastedData.charAt(0);
					if (regex.test(char)) {
						this.$set(this.otpInputs, i, char);
						pastedData = pastedData.substring(1);
					}
				}
			}

			// Check if all fields are filled
			if (this.isOTPComplete()) {
				this.sendTwoFactor();
			}
		},
		focusNextInput (index) {
			this.$refs['otpInput'][index + 1].focus();
		},
		focusPreviousInput (index) {
			this.$refs['otpInput'][index - 1].focus();
		},
		isOTPComplete () {
			return this.otpInputs.every(input => input !== '');
		},
		getInputMode () {
			// Set inputmode to "numeric" to provide a numerical keyboard on mobile devices
			return this.method === 'recovery' ? 'text' : 'numeric';
		},
		resendCode (){
			let method = this.method
			this.$store.dispatch('twoFactorRequest', { method })
		},
		countdown (){
			clearInterval(this.timeoutTimer);
			this.timeLeft = this.twoFactorRequestTimeout
			if(this.timeLeft > 0){
				this.requestNew = true;
			}
			this.timeoutTimer = setInterval(() => {
				if(this.timeLeft == 0){
					clearInterval(this.timeoutTimer);
					this.requestNew = false;
				}
				else{
					this.timeLeft -= 1;
				}
			}, 1000);
		},
		sendTwoFactor () {
			let recaptcha = this.recaptcha.recaptchaCode;
			let twoFactorCode = this.otpInputs.join('');
			let method = this.method;
			this.$store.dispatch('sendTwoFactor', { twoFactorCode, recaptcha, method }).then(
				response => {
					if(this.termsRequired){
						this.$router.push({
							name: "agreeToTerms",
						});
					}
					if(this.twoFactorSuccess && !this.termsRequired && !this.rememberRequestRequired){
						if (this.miniView) {
							this.postLoginMessage('success')
							return
						}
						window.top.location.href = response.body.url;
					}
					else if(this.twoFactorError && this.twoFactorErrors !== undefined){
						this.$eToast.error(this.$i18n.t(`password_login.${this.twoFactorErrors}`));
					}else if(this.twoFactorError){
						this.$eToast.error(this.$i18n.t(`password_login.submit_error`));
					}
				},

			)
		},
		cancel (){
			this.$router.push({
				name: "login",
			});
		},
	},
};
</script>