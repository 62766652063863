// ALL Vue Resource CONFIGURATION

import Vue from "vue";
import VueResource from "vue-resource";
import { getEnv } from "../env";

Vue.use(VueResource);

Vue.http.options.root = getEnv('VUE_APP_FRONTEND_APP_URL');

export function loginUser (email, password, recaptcha, twoFactorCode, token) {
	var options = {
		email,
		password,
		recaptcha,
		two_factor_code: twoFactorCode,
		token,
	};
	return Vue.http
		.post("login", options, {
			emulateJSON: true,
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function registerUser (
	firstName,
	lastName,
	idCode,
	issuer,
	dateOfBirth,
	gender,
	email,
	emailConfirmation,
	password,
	passwordConfirmation,
	wishPartnerAds,
	countryCode,
	phoneNumber,
	country,
	county,
	city,
	street,
	postalIndex,
	thirdPartyHash,
	inviteType,
	inviteHash,
) {
	var options = {
		firstName,
		lastName,
		idCode,
		issuer,
		dateOfBirth,
		gender,
		email,
		email_confirmation: emailConfirmation,
		password,
		password_confirmation: passwordConfirmation,
		wish_partner_ads: wishPartnerAds,
		...(countryCode && countryCode != '' && { country_code: countryCode }), //send only if not empty string
		...(phoneNumber && phoneNumber != '' && { phone_number: phoneNumber }), //send only if not undefined and !== ''
		country,
		county,
		city,
		street,
		postal_index: postalIndex,
		...(thirdPartyHash ? { third_party_hash: thirdPartyHash } : {}),
	};
	return Vue.http
		.post("create-account", options, {
			params: {
				inviteType,
				inviteHash,
			},
			emulateJSON: true,
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function forgotRequest (username,recaptcha,token) {
	var options = {
		username,
		recaptcha,
		token,
	};
	return Vue.http
		.post("password/reset/request", options, {
			emulateJSON: true,
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function getReset () {
	return Vue.http
		.get("password/reset/{token}", {
			emulateJSON: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function validateToken (token,type) {
	var options = {
		token,
		type,
	};
	return Vue.http
		.post("password/reset/validate", options, {
			emulateJSON: true,
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function resetPassword (
	token,
	username,
	password,
	passwordConfirmation,
) {
	var options = {
		token,
		username,
		password,
		password_confirmation: passwordConfirmation,
	};
	return Vue.http
		.post("password/reset", options, {
			emulateJSON: true,
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//mykoob internal password change
export function changePassword (
	token,
	username,
	oldPassword,
	password,
	passwordConfirmation,
) {
	var options = {
		token,
		username,
		old_password: oldPassword,
		password,
		password_confirmation: passwordConfirmation,
	};
	return Vue.http
		.post("password/change", options, {
			emulateJSON: true,
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function adminChangePassword (
	token,
	username,
	password,
	passwordConfirmation,
) {
	var options = {
		token,
		username,
		password,
		password_confirmation: passwordConfirmation,
	};
	return Vue.http
		.post("password/admin-change", options, {
			emulateJSON: true,
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function loginIdCard () {
	var options = {};
	return Vue.http
		.post(getEnv('VUE_APP_FRONTEND_IDCARD_URL') + "id-card", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function loginCardSmart (country, personalCode, recaptcha) {
	var options = {
		country,
		personalCode,
		recaptcha,
	};
	return Vue.http
		.post("smart-id", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}
export function loginCardSmartCheck (recaptcha) {
	var options = {
		recaptcha,
	};
	return Vue.http
		.post("smart-id/check-status", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function loginCardMobile (personalCode, phoneNumber, recaptcha) {
	var options = {
		personalCode,
		phoneNumber,
		recaptcha,
	};
	return Vue.http
		.post("mobile-id", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}
export function loginCardMobileCheck (recaptcha) {
	var options = {
		recaptcha,
	};
	return Vue.http
		.post("mobile-id/check-status", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function getCountry () {
	return Vue.http
		.get("https://get.geojs.io/v1/ip/country.json", {
			emulateJSON: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function selectUser (id) {
	var options = {
		id,
	};
	return Vue.http
		.post("/select-user", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function chooseUser () {
	var options = {};
	return Vue.http
		.post("har-id/allowed-users", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//start of register

export function registerCardMobile (personalCode, phoneNumber, recaptcha) {
	var options = {
		personalCode,
		phoneNumber,
		recaptcha,
	};
	return Vue.http
		.post("register/mobile-id", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}
export function registerCardMobileCheck () {
	var options = {};
	return Vue.http
		.post("register/mobile-id/check-status", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function registerCardSmart (country, personalCode, recaptcha) {
	var options = {
		country,
		personalCode,
		recaptcha,
	};
	return Vue.http
		.post("register/smart-id", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}
export function registerCardSmartCheck (recaptcha) {
	var options = {
		recaptcha,
	};
	return Vue.http
		.post("register/smart-id/check-status", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function registerIdCard () {
	var options = {};
	return Vue.http
		.post(getEnv('VUE_APP_FRONTEND_IDCARD_URL') + "register/id-card", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function getHarData () {
	var options = {};
	return Vue.http
		.post("register/har-id/user", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function registerEmailValidate (email) {
	var options = { email };
	return Vue.http
		.post("/register/email/validate", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function registerPhoneVerifyRequest (countryCode, phoneNumber, token) {
	var options = {
		country_code: countryCode,
		phone_number: phoneNumber,
		token,
	};
	return Vue.http
		.post("/register/phone/verify/request", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function registerPhoneVerifyCode (countryCode, phoneNumber, code) {
	var options = {
		country_code: countryCode,
		phone_number: phoneNumber,
		code,
	};
	return Vue.http
		.post("/register/phone/verify/code", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}
//end of register

export function createAccSameId (hash) {
	var options = {
		hash,
	};
	return Vue.http
		.post("create-account/proceed", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//school-register

export function schoolRegister (
	schoolName,
	registrationNumber,
	type,
	street,
	city,
	country,
	firstName,
	lastName,
	email,
	number,
) {
	var options = {
		schoolName,
		registrationNumber,
		type,
		street,
		city,
		country,
		firstName,
		lastName,
		email,
		number,
	};
	return Vue.http
		.post("school-register", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//two-factor
export function twoFactor (twoFactorCode, recaptcha, method) {
	var options = {
		two_factor_code: twoFactorCode,
		recaptcha,
		method,
	};
	return Vue.http
		.post("two-factor", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function getXidData () {
	var options = {

	}
	return Vue.http
		.post('register/x-id/user', options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

export function getThirdPartyData () {
	return Vue.http.get('/oauth/session', {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

export function acceptThirdPartyRequest (state, clientId) {
	let options = {
		state,
		client_id: clientId,
	}
	return Vue.http.post('/oauth/authorize', options, {
		headers: {
			"content-type": "application/x-www-form-urlencoded",
		},
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

export function denyThirdPartyRequest (state, clientId) {
	let options = {
		state,
		client_id: clientId,
	}
	return Vue.http.delete('/oauth/authorize', options, {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

export function getRoles () {
	return Vue.http.post('/oauth/roles', {
		headers: {
			"content-type": "application/x-www-form-urlencoded",
		},
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

//terms
export function getServiceTerms () {
	return Vue.http
		.get("/terms/service-terms", {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function getCookiesPrivacyPolicyTerms () {
	return Vue.http
		.get("/terms/cookies-privacy-policy", {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function getPrivacyPolicyTerms () {
	return Vue.http
		.get("/terms/privacy-policy", {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//dowload terms
export function downloadServiceTerms () {
	return Vue.http
		.get("/terms/service-terms/download", {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function downloadCookiesPrivacyPolicyTerms () {
	return Vue.http
		.get("/terms/cookies-privacy-policy/download", {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function downloadPrivacyPolicyTerms () {
	return Vue.http
		.get("/terms/privacy-policy/download", {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function acceptTerms (termsAccepted) {
	var options = {
		terms_accepted: termsAccepted,
	};
	return Vue.http
		.post("/terms/accept", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

export function getServiceTermsRead () {
	return Vue.http
		.get("/terms/service-terms/read", {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//two-factor request
export function twoFactorRequest (method) {
	var options = {
		method,
	};
	return Vue.http
		.post("two-factor/request", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//two-factor remeber device
export function twoFactorRemember (remember) {
	var options = {
		remember,
	};
	return Vue.http
		.post("two-factor/remember", options, {
			emulateJSON: true,
			credentials: true,
		})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		);
}

//verify user data
export function getRedirect (target) {
	let options = {
		target,
	}
	return Vue.http.post('/goto', options, {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

//google registration
export function getGoogleRegisteringUser () {
	return Vue.http.get('/oauth/google/register/user', {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

//microsoft registration
export function getMicrosoftRegisteringUser () {
	return Vue.http.get('/oauth/microsoft/register/user', {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

//eliis registration
export function getEliisRegisteringUser () {
	return Vue.http.get('/oauth/eliis/register/user', {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

//get two factor methods
export function getTwoFactorMethods () {
	return Vue.http.get('/two-factor/methods', {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

//third party login (after declining linking)
export function thirdPartyLogin (method, hash) {
	let options = {
		hash,
	}
	return Vue.http.post(`/api/login/${method}`, options, {
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}

//get teacher invite data
export function getTeacherInviteData (hash) {
	return Vue.http.get(`register/teacher-invite-data`, {
		params: {
			hash,
		},
		emulateJSON: true,
		credentials: true,
	})
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error),
		)
}