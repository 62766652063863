<template>
	<div class="mb-0 d-flex flex-column align-center">
		<span
			class="body-3"
			style="font-weight: 500!important;"
			:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
			{{ $t('third_party_login.sign_in_with') }}
		</span>
		<div
			class="d-flex mt-4 mb-6"
			style="width: 100%;">
			<e-tooltip
				v-for="(method, index) in thirdPartyMethods"
				:key="index"
				:disabled="$vuetify.breakpoint.mobile"
				top>
				<template #activator="{on}">
					<e-button
						class="elevation-1 flex-grow-1"
						:class="{'mr-2': index + 1 < thirdPartyMethods.length}"
						:href="register ? method.registerUrl : method.loginUrl"
						:target="iframeView ? '_parent' : '_self'"
						variant="tertiary"
						size="large"
						large
						v-on="on">
						<img
							:src="require(`../assets/${method.logo}.svg`)"
							alt="logo"
							height="19px">
					</e-button>
				</template>
				{{ method.displayName }}
			</e-tooltip>
		</div>
		<div
			class="d-flex align-center"
			style="width: 100%;">
			<div class="line" />
			<span
				class="body-3 mx-3"
				style="font-weight: 500!important;"
				:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
				{{ $t('third_party_login.or') }}
			</span>
			<div class="line" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getEnv } from '../env';
export default {
	props:{
		register: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			availableMethods: [
				{ name: 'google', displayName: 'Google', logo: 'google-logo', loginUrl: '/oauth/google/redirect', registerUrl: '/oauth/google/register/redirect' },
				{ name: 'microsoft', displayName: 'Microsoft', logo: 'microsoft-logo', loginUrl: '/oauth/microsoft/redirect', registerUrl: '/oauth/microsoft/register/redirect' },
				{ name: 'eliis', displayName: 'ELIIS', logo: 'eliis-logo', loginUrl: '/oauth/eliis/redirect', registerUrl: '/oauth/eliis/register/redirect' },
			],
			allowedMethods: getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(", "),
			thirdPartyMethods: [],
		};
	},
	computed: {
		...mapGetters([
			'iframeView',
		]),
	},
	mounted (){
		this.handleAvailableMethods()
	},
	methods: {
		handleAvailableMethods (){
			this.thirdPartyMethods = this.availableMethods.filter(method => this.allowedMethods.includes(method.name))
		},	
	},
};
</script>