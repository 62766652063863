import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'


Vue.use(Vuex)

export default{
	state: {
		passwordLoginBusy: false,
		passwordError: false,
		passwordLoggedIn: false,
		passwordUrl: undefined,
		passwordShowRecaptcha: false,
		passwordShowTwoFactor: false,
		passwordSuccess: false,
		passwordErrors: false,
		twoFactorBusy: false,
		twoFactorError: false,
		twoFactorErrors: undefined,
		twoFactorSuccess: false,
		twoFactorAvailableMethods: undefined,
		twoFactorRequestBusy: false,
		twoFactorRequestSuccess: false,
		twoFactorRequestError: false,
		twoFactorRequestErrors: undefined,
		twoFactorRequestTimeout: 0,
		twoFactorRequestEmail: undefined,
		twoFactorRequestPhoneNumber: undefined,
		passwordRedirect: true,
		termsRequired: false,
		rememberRequestRequired: false,
		twoFactorRememberBusy: false,
		twoFactorRememberSuccess: false,
		twoFactorRememberError: false,
		twoFactorRememberErrors: undefined,
		getTwoFactorMethodsBusy: false,
		getTwoFactorMethodsSuccess: false,
		getTwoFactorMethodsErrors: undefined,
	},
	mutations: {
		loginUser (state){
			state.passwordLoginBusy = true;
		},
		loginUserSuccess (state,response){
			state.passwordLoggedIn = response.data.success;
			state.passwordShowTwoFactor = response.data.two_factor_required;
			state.twoFactorAvailableMethods = response.data.available_methods;
			state.passwordShowRecaptcha = response.data.show_captcha;
			state.passwordUrl = response.data.url;
			state.passwordRedirect = response.data.redirect;
			state.termsRequired = response.data.terms_acception_required
			if (!state.passwordShowRecaptcha && !state.passwordShowTwoFactor && state.passwordloggedIn && !state.termsRequired) {
				if (this.state.miniView) {
					this.state.miniViewFunction('success')
					return
				}
				//iframe login stop redirect
				else if(response.data.redirect == "false") {
					this.state.miniViewFunction('success')
					return
				}
				window.top.location.href = response.data.url;
			}
			return state;
		},
		loginUserFail (state,error){
			state.passwordLoginBusy = false;
			state.passwordShowRecaptcha = error.body.show_captcha;
			state.passwordError = true;
			state.passwordErrors = error.body.errors;
			if (error.data.session_active === false) {
				state.passwordLoggedIn = false;
				state.passwordShowTwoFactor = false;
			}else{
				state.passwordError = true;
				/*
				store.commit("changeError",
					i18n.t("forgot_password.user_not_found"));
				store.commit("showError");
				*/
			}
			return state;
		},
		sendTwoFactor (state){
			state.twoFactorBusy = true;
			state.twoFactorError = false;
		},
		sendTwoFactorSuccess (state,response){
			state.termsRequired = response.body.terms_acception_required
			state.rememberRequestRequired = response.body.remember_request_required
			if(response.body.success && response.body.remember_request_required){
				state.twoFactorBusy = false;
			}
			else if(response.body.success){
				state.twoFactorSuccess = true;
			}
			else{
				state.twoFactorSuccess = false;
				state.twoFactorBusy = false;
			}
		},
		sendTwoFactorFail (state,error){
			state.twoFactorBusy = false;
			if(!error.body.success && error.body.errors){
				state.twoFactorError = true;
				state.twoFactorErrors = error.body.errors;
				if(error.body.show_captcha){
					state.passwordShowRecaptcha = true;
				}
			}
			else{
				state.twoFactorError = true;
			}
		},
		twoFactorRequest (state){
			state.twoFactorRequestBusy = true
			state.twoFactorRequestSuccess = false
			state.twoFactorRequestError = false
			state.twoFactorRequestErrors = undefined
			state.twoFactorRequestTimeout = 0
		},
		twoFactorRequestSuccess (state, response){
			state.twoFactorRequestBusy = true
			if(response.body.success){
				state.twoFactorRequestSuccess = true;
			}else{
				state.twoFactorRequestError = true;
			}
			if(response.body.timeout){
				state.twoFactorRequestTimeout = response.body.timeout;
			}
			state.twoFactorRequestEmail = response.body.email || undefined
			state.twoFactorRequestPhoneNumber = response.body.phone_number || undefined
		},
		twoFactorRequestFail (state, error){
			state.twoFactorRequestBusy = true
			state.twoFactorRequestError = true;
			if(error.body.errors){
				state.twoFactorRequestErrors = error.body.errors;
			}
			if(error.body.timeout){
				state.twoFactorRequestTimeout = error.body.timeout;
			}
		},
		setPasswordLoginBusy (state, value){
			state.passwordLoginBusy = value
		},
		twoFactorRemember (state){
			state.twoFactorRememberBusy = true
		},
		twoFactorRememberSuccess (state, response){
			state.twoFactorRememberBusy = false
			state.termsRequired = response.body.terms_acception_required
			if(response.body.success){
				state.twoFactorRememberSuccess = true;
			}
		},
		twoFactorRememberFail (state, error){
			state.twoFactorRememberBusy = false
			state.twoFactorRememberError = true;
			if(error.body.errors){
				state.twoFactorRememberErrors = error.body.errors;
			}
		},
		getTwoFactorMethods (state){
			state.getTwoFactorMethodsBusy = true
		},
		getTwoFactorMethodsSuccess (state, response){
			state.getTwoFactorMethodsBusy = false
			state.getTwoFactorMethodsSuccess = response.body.success
			state.twoFactorAvailableMethods = response.body.methods
		},
		getTwoFactorMethodsFail (state, error){
			state.getTwoFactorMethodsSuccess = error.body.success
			state.getTwoFactorMethodsErrors = error.body.error;
		},
	},
	actions: {
		loginUser: (store, { email, password, recaptcha, twoFactorCode, token }) => {
			store.commit('loginUser')
			return api.loginUser(email, password, recaptcha, twoFactorCode, token)
				.then(
					response => {
						store.commit('loginUserSuccess',response)
						return response;
					},
					error => {
						store.commit('loginUserFail', error)
						return error;
					},
				)
		},
		sendTwoFactor: (store, { twoFactorCode, recaptcha, method }) => {
			store.commit('sendTwoFactor')
			return api.twoFactor(twoFactorCode, recaptcha, method )
				.then(
					response => {
						store.commit('sendTwoFactorSuccess',response)
						return response;
					},
					error => {
						store.commit('sendTwoFactorFail', error)
						return error;
					},
				)
		},
		twoFactorRequest: (store, { method }) => {
			store.commit('twoFactorRequest')
			return api.twoFactorRequest(method )
				.then(
					response => {
						store.commit('twoFactorRequestSuccess',response)
						return response;
					},
					error => {
						store.commit('twoFactorRequestFail', error)
						return error;
					},
				)
		},
		twoFactorRemember: (store, { remember }) => {
			store.commit('twoFactorRemember')
			return api.twoFactorRemember(remember )
				.then(
					response => {
						store.commit('twoFactorRememberSuccess',response)
						return response;
					},
					error => {
						store.commit('twoFactorRememberFail', error)
						return error;
					},
				)
		},
		getTwoFactorMethods: (store) => {
			store.commit('getTwoFactorMethods')
			return api.getTwoFactorMethods()
				.then(
					response => {
						store.commit('getTwoFactorMethodsSuccess',response)
						return response;
					},
					error => {
						store.commit('getTwoFactorMethodsFail', error)
						return error;
					},
				)
		},
	},
	getters: {
		passwordLoginBusy: state => state.passwordLoginBusy,
		passwordError: state => state.passwordError,
		passwordErrors: state => state.passwordErrors,
		passwordLoggedIn: state => state.passwordLoggedIn,
		passwordUrl: state => state.passwordUrl,
		passwordShowRecaptcha: state => state.passwordShowRecaptcha,
		passwordShowTwoFactor: state => state.passwordShowTwoFactor,
		passwordRedirect: state => state.passwordRedirect,
		twoFactorBusy: state => state.twoFactorBusy,
		twoFactorError: state => state.twoFactorError,
		twoFactorErrors: state => state.twoFactorErrors,
		twoFactorSuccess: state => state.twoFactorSuccess,
		twoFactorAvailableMethods: state => state.twoFactorAvailableMethods,
		termsRequired: state => state.termsRequired,
		twoFactorRequestBusy: state => state.twoFactorRequestBusy,
		twoFactorRequestSuccess: state => state.twoFactorRequestSuccess,
		twoFactorRequestError: state => state.twoFactorRequestError,
		twoFactorRequestErrors: state => state.twoFactorRequestErrors,
		twoFactorRequestTimeout: state => state.twoFactorRequestTimeout,
		twoFactorRequestEmail: state => state.twoFactorRequestEmail,
		twoFactorRequestPhoneNumber: state => state.twoFactorRequestPhoneNumber,
		rememberRequestRequired: state => state.rememberRequestRequired,
		twoFactorRememberBusy: state => state.twoFactorRememberBusy,
		twoFactorRememberSuccess: state => state.twoFactorRememberSuccess,
		twoFactorRememberError: state => state.twoFactorRememberError,
		twoFactorRememberErrors: state => state.twoFactorRememberErrors,
		getTwoFactorMethodsBusy: state => state.getTwoFactorMethodsBusy,
		getTwoFactorMethodsSuccess: state => state.getTwoFactorMethodsSuccess,
		getTwoFactorMethodsErrors: state => state.getTwoFactorMethodsErrors,
	},
}