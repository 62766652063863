<template>
	<div class="smart-id mt-7">
		<ValidationObserver
			v-slot="{handleSubmit}">
			<form
				v-if="!mobileCodeSent && userNotFound == false"
				@submit.prevent="mobileLoginBusy ? '' : handleSubmit(login)">
				<ValidationProvider
					v-slot="{errors}"
					mode="passive"
					name="id-code"
					rules="required"
					slim>
					<div class="py-4">
						<e-text-field
							v-model="idCode"
							large=""
							:label="$i18n.t('mobile_id_login.id_code')"
							:error="errors[0] && errors[0] !== ''"
							:error-messages="errors[0]" />
					</div>
				</ValidationProvider>
				<div class="phone-container py-2">
					<ValidationProvider
						mode="passive"
						rules="required"
						name="code"
						slim>
						<div class="py-4 mr-2 phone-container__code">
							<e-select
								v-model="code"
								large=""
								class="body-2"
								:value="code"
								:label="$i18n.t('mobile_id_login.code')"
								:items="mobilePrefixes" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						mode="passive"
						name="phone"
						rules="required|numeric"
						slim>
						<div class="py-4 phone-container__number flex">
							<e-text-field
								v-model="number"
								large=""
								class="display-inline"
								:label="$i18n.t('mobile_id_login.phone_number')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
				</div>
				<div class="d-block text-center mx-auto">
					<vue-recaptcha
						v-show="mobileShowRecaptcha"
						ref="recaptcha"
						class="recaptcha"
						:sitekey="recaptcha.siteKey"
						size="normal"
						:load-recaptcha-script="true"
						@verify="onVerify" />
				</div>
				<e-button
					class="mt-4"
					:block="true"
					type="submit"
					:loading="mobileLoginBusy"
					variant="primary"
					size="large"
					large>
					{{ $t('password_login.login') }}
				</e-button>
			</form>
		</validationobserver>

		<div
			v-if="mobileCodeSent && !mobileLoggedIn">
			<p class="large-title text-center mb-3">
				{{ mobileCode }}
			</p>
			<p class="subhead-1 text-center mb-0 px-10">
				{{ $t('mobile_id_login.check_code') }}
			</p>
			<div class="text-center mt-6">
				<e-button
					variant="link"
					small=""
					@click="cancelPing()">
					{{ $t('mobile_id_login.cancel') }}
				</e-button>
			</div>
		</div>
		<div
			v-if="mobileLoggedIn"
			class="text-center">
			<e-circular-loading
				class="pt-4"
				value
				:fullPage="false"
				:size="42" />
			<p class="subhead-1 text-center mb-0 px-10 mt-10">
				{{ $t('smart_id_login.auth_successful') }}
			</p>
		</div>
	</div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
/*import {
	loginCardMobile,
	loginCardMobileCheck,
	registerCardMobile,
	registerCardMobileCheck,
} from "../api";*/
import { mapGetters } from "vuex";
import { getEnv } from "../env";
export default {
	components: {
		VueRecaptcha,
	},
	props: {
		register: Boolean,
	},
	data () {
		return {
			codeSent: false,
			userNotFound: false,
			idCode: "",
			number: undefined,
			code: "+372",
			mobilePrefixes: ["+372","+370"],
			fullNumber: "",
			ping: false,
			accounts: {},
			user: {},
			recaptcha: {
				recaptcha: "",
				siteKey: getEnv('VUE_APP_FRONTEND_RECAPTCHA_SITE_KEY'),
			},
			empty: {
				fullNumber: false,
			},
		};
	},
	computed:{
		...mapGetters([
			'mobileLoginBusy',
			'mobileError',
			'mobileLoggedIn',
			'mobileLoginUrl',
			'mobileShowRecaptcha',
			'mobileSuccess',
			'mobileErrors',
			'mobileCodeSent',
			'mobileCode',
			'mobileAccounts',
			'mobileUser',
			'mobilePing',
		]),
	},
	methods: {
		login () {
			this.fullNumber = this.code.concat(this.number);

			if (this.register == false) {
				this.loginMobileId();
			} else {
				this.registerMobileId();
			}
		},
		//login
		loginMobileId () {
			var personalCode = this.idCode;
			var phoneNumber = this.fullNumber;
			var recaptcha = this.recaptcha.recaptcha;
			this.$store.commit("resetMobileState");
			this.$store.dispatch('loginCardMobile', { personalCode, phoneNumber, recaptcha }).then(
				() => {
					//this.loginPingMobileId();
					if(this.mobileCodeSent){
						this.loginPingMobileId();
					}
					else if(this.mobileError && this.mobileErrors !== ""){
						this.$store.commit("changeError",
							("mobile_id_login." + this.mobileErrors));
						this.$store.commit("showError",true);
					}

					else if(this.mobileError && this.mobileErrors == ""){
						this.$store.commit("changeError",
							("mobile_id_login." + this.mobileErrors));
						this.$store.commit("showError",true);
					}
					if(this.mobileShowRecaptcha){
						this.resetRecaptcha();
					}

				},
			)
		},
		cancelPing (){
			this.$store.commit("cancelMobilePing");
			//this.$store.commit("loginSmartUser");
		},
		loginPingMobileId () {
			//if(this.mobilePing == true){
			this.ping = setInterval(() => {
				if(this.mobilePing){
					this.$store.dispatch("loginPingMobile");
				}
				else{
					this.cancelPing();
					clearInterval(this.ping);
				}
				if(this.mobileLoggedIn){
					window.top.location.href = this.mobileLoginUrl;
				}
				if(this.mobileAccounts){
					this.$router.push({
						name: "chooseUser",
						params: {
							users: this.mobileAccounts,
						},
					});
				}
				if(this.mobileErrors){
					if(this.$te("mobile_id_login." + this.mobileErrors)){
						this.$store.commit("changeError",
							("mobile_id_login." + this.mobileErrors));
						this.$store.commit("showError",true);
					}
					else{
						this.$store.commit("changeError",
							("repeating_errors." + this.mobileErrors));
						this.$store.commit("showError",true);
					}
					this.cancelPing();
					clearInterval(this.ping);
				}

			}, 3000);
		},
		//registration
		registerMobileId () {
			var personalCode = this.idCode;
			var phoneNumber = this.fullNumber;
			var recaptcha = this.recaptcha.recaptcha;
			this.$store.commit("resetMobileState");
			this.$store.dispatch('registerCardMobile', { personalCode, phoneNumber, recaptcha }).then(
				() => {

					if(this.mobileCodeSent){
						this.registerPingMobileId();
					}
					else if(this.mobileError && this.mobileErrors !== ""){
						this.$store.commit("changeError",
							("mobile_id_login." + this.mobileErrors));
						this.$store.commit("showError",true);
					}else if(this.mobileError && this.mobileErrors == ""){
						this.$store.commit("changeError",
							("mobile_id_login." + this.mobileErrors));
						this.$store.commit("showError",true);
					}else if(this.mobileShowRecaptcha){
						this.resetRecaptcha();
					}

				},


			)
		},
		registerPingMobileId () {
			this.ping = setInterval(() => {
				if(this.mobilePing){
					this.$store.dispatch("registerPingMobile");
				}
				else{
					this.$store.commit("cancelMobilePing");
					clearInterval(this.ping);
				}
				if(this.mobileError && this.mobileErrors !== ""){
					if(this.$te("mobile_id_login." + this.mobileErrors)){
						this.$store.commit("changeError",
							("mobile_id_login." + this.mobileErrors));
						this.$store.commit("showError",true);
					}
					else{
						this.$store.commit("changeError",
							("repeating_errors." + this.mobileErrors));
						this.$store.commit("showError",true);
					}
					this.$store.commit("cancelMobilePing");
					clearInterval(this.ping);
				}
				if(this.mobileUser){
					this.$router.push({
						name: "setupAccount",
						params: {
							user: this.mobileUser,
						},
					});
				}
			}, 3000);

		},
		//end of registration

		//end fapi functions
		onVerify (response) {
			this.recaptcha.recaptcha = response;
			this.spinner = false;
		},
		resetRecaptcha () {
			this.$refs.recaptcha.reset(); // Direct call reset method
		},
		registerUser () {
			this.userNotFound = false;
			this.$router.push({
				name: "setupAccount",
				params: {
					user: this.user,
				},
			});
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
