<template>
	<div class="view-content__wrapper">
		<div class="view-content view-content--wide d-block d-sm-flex">
			<div class="verify-data mx-auto">
				<div class="d-flex justify-center align-center">
					<div class="logo-container mr-2">
						<img
							src="../assets/ekool-logo.svg"
							alt="eKool logo">
						<img
							class="logo-container__protected-logo"
							src="../assets/shape.svg"
							alt="attention logo">
					</div>
					<div class="arrow-icons">
						<v-icon
							size="20"
							class="arrow-icons__arrow-left">
							{{ $vuetify.theme.dark ? 'icon-arrow-left grayscale600--text' : 'icon-arrow-left grayscale400--text' }}
						</v-icon>
						<v-icon
							size="20"
							class="arrow-icons__arrow-right">
							{{ $vuetify.theme.dark ? 'icon-arrow-right grayscale600--text' : 'icon-arrow-right grayscale400--text' }}
						</v-icon>
					</div>
					<div class="logo-container">
						<img
							style="width: 84px;"
							src="../assets/identityIllustration.png"
							alt="identity illustration">
					</div>
				</div>
				<div class="verify-data__header">
					<h1
						class="headline-1 graybase--text mb-1 text-center"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("verify_data.title") }}
					</h1>
					<p
						class="body-3 text-center mb-0"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
						{{ $t("verify_data.description") }}
					</p>
				</div>
				<div class="verify-data__boxes">
					<div class="verify-data__body">
						<span
							class="subhead-2 mb-3"
							:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
							{{ $t('verify_data.body_title_1') }}
						</span>
						<div class="verify-data__box_items">
							<div class="display-flex">
								<span
									class="body-3"
									:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'"
									v-html="$t('verify_data.body_item_1_1')" />
								<a
									:href="computedLink"
									class="body-3"
									target="_blank"
									:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
									{{ $t('verify_data.body_item_1_link') }}
								</a>
							</div>
							<span
								class="body-3"
								:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
								{{ $t('verify_data.body_item_2') }}
							</span>
						</div>
					</div>
					<div class="verify-data__body">
						<span
							class="subhead-2 mb-3"
							:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
							{{ $t('verify_data.body_title_2') }}
						</span>
						<div class="verify-data__box_items">
							<div
								class="body-3"
								:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'"
								v-html="$t('verify_data.body_item_3')" />
							<div
								class="body-3"
								:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'"
								v-html="$t('verify_data.body_item_4')" />
						</div>
					</div>
				</div>
				<div class="verify-data__notes">
					<img
						:src="require(`../assets/info.svg`)"
						alt="info logo">
					<span
						class="verify-data__notes_description"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
						{{ $t('verify_data.note') }}
					</span>
				</div>
				<div class="verify-data__footer">
					<e-button
						variant="primary"
						:block="true"
						size="large"
						large
						@click="goToProfile()">
						{{ $t("verify_data.update_profile") }}
					</e-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data () {
		return {
			bodyItems: [
				{ icon: 'icon-letter', text: 'verify_data.body_item_1' },
				{ img: 'key-icon', text: 'verify_data.body_item_2' },
				{ icon: 'icon-bell', text: 'verify_data.body_item_3' },
				{ icon: 'icon-paper-pages', text: 'verify_data.body_item_4' },
			],
			logoutTimeout: null,
		};
	},
	computed: {
		...mapGetters ([
			'getRedirectBusy',
			'getRedirectSuccess',
			'redirectUrl',
		]),
		computedLink () {
			return this.$i18n.locale == 'et'
				? 'https://help.ekool.eu/et_ET/kasutajate-andmete-t%C3%B6%C3%B6tlemine-ekoolis/kaheastmeline-autentimine-2fa'
				: 'https://help.ekool.eu/en_US/kasutajate-andmete-t%C3%B6%C3%B6tlemine-ekoolis/kaheastmeline-autentimine-2fa';
		},
	},
	mounted (){
		this.logout()
	},
	beforeDestroy () {
		if(this.logoutTimeout){
			clearTimeout(this.logoutTimeout);
		}
	},
	methods: {
		skip () {
			const target = 'home';
			this.$store.dispatch('getRedirect', { target }).then(() => {
				if (this.redirectUrl) {
					window.top.location.href = this.redirectUrl;
				}
			});
		},
		goToProfile () {
			const target = 'profile';
			this.$store.dispatch('getRedirect', { target }).then(() => {
				if (this.redirectUrl) {
					window.top.location.href = this.redirectUrl;
				}
			});
		},
		logout () {
			//logout after 5 minutes
			this.logoutTimeout = setTimeout(() => {
				window.top.location.href = `/logout`;
			}, 300000);
		},
	},
};
</script>