<template>
	<v-app>
		<v-container
			id="app"
			:fluid="true">
			<vue-headful
				v-if="project == 'eKool'"
				:title="$t('browser_title.ekool_title')" />
			<vue-headful
				v-if="project == 'Mykoob'"
				:title="$t('browser_title.mykoob_title')" />
			<v-container
				:fluid="true"
				class="page__container flex"
				:class="{'iframe-padding': iframeView}">
				<v-row
					class="page__content"
					:class="[{'align-content-center': !showFooter && !showNav}, {'justify-center': showIlustration}]">
					<v-col
						v-if="showNav"
						class="nav-container pa-0"
						cols="12">
						<Navbar />
					</v-col>
					<div class="page__content__centered">
						<v-col
							class="align-center justify-center"
							:class="showIlustration ? 'd-none d-lg-flex' : 'd-none'"
							cols="12"
							lg="6">
							<div class="d-flex align-content-center">
								<img
									v-if="$route.name == 'maintenance'"
									class="ilustration"
									src="./assets/father_and_son_fixing_bike@2x.png"
									alt="father and son fixing bike">
								<img
									v-else-if="!$vuetify.theme.dark"
									class="ilustration"
									src="./assets/illustration.svg"
									alt="ilustration">
								<img
									v-else-if="$vuetify.theme.dark"
									class="ilustration"
									src="./assets/illustration-dark.svg"
									alt="ilustration">
							</div>
						</v-col>
						<v-col
							cols="12"
							class="px-4"
							:lg="showIlustration ? 6 : 12">
							<router-view />
						</v-col>
					</div>
					
					<v-container
						v-if="showFooter"
						class="px-0 pt-0 pt-sm-3 pb-0"
						:fluid="true">
						<Footer />
					</v-container>
				</v-row>
			</v-container>
		</v-container>
	</v-app>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters, mapState } from "vuex";
import { getEnv } from "./env";

export default {
	components: {
		Navbar,
		Footer,
	},
	data () {
		return {
			iframe: false,
			permissions: false,
			project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
		};
	},
	computed: {
		...mapState ([
			'miniView',
			'mobileView',
		]),
		...mapGetters([
			'showFooter',
			'showNav',
			'showIlustration',
			'iframeView',
			'CFTurnstileWidgetId',
			'disableButton',
		]),
	},
	watch: {
		disableButton (val) {
			if(val){
				if(this.mobileView){
					setTimeout(() => {
						this.$store.commit("setDisableButton",false);
					}, 5000);
				}
			}
		},
	},
	beforeMount () {
		//redirect parent if app in iframe
		if(window !== window.parent){
			window.parent.location.href = getEnv('VUE_APP_FRONTEND_APP_URL')
			window.location.href = getEnv('VUE_APP_FRONTEND_APP_URL')
		}
		//route + global layout check
		if(this.$route.name == "login-mini" || this.$route.name == 'thirdParty'){
			this.$store.commit("showFooter",false);
			this.$store.commit("showNav",false);
			this.$store.commit("showIlustration",false);
			this.$store.commit("setMiniView",true);
			this.$store.commit("setMiniViewFunction", this.postLoginMessage);
		}
		else if(this.$route.name == "iframe"){
			this.$store.commit("setIframeView",true);
			this.$store.commit("showFooter",false);
			this.$store.commit("showNav",false);
		}
		else if(this.$route.name == "mobile" ){
			this.$store.commit("setMobileView",true);
			this.$store.commit("showFooter",false);
			this.$store.commit("showNav",false);
			this.$store.commit("showIlustration",false);
			setTimeout(() => {
				this.$store.commit("setDisableButton",false);
			}, 5000);
		}
		else if(this.$route.name == 'verifyData'){
			this.$store.commit("showIlustration",false);
			this.$store.commit("showMobileAppPromo",false);
		}
		if(this.$route.name == "permissions"){
			this.permissions = true;
		}
	},
	created (){
		this.initTheme();
	},
	mounted (){
		// Define a callback function for when the script is loaded
		window.onloadTurnstileCallback = () => {
			this.$store.commit("setCFTurnstileScriptLoaded",true);
		};
	},
	methods: {
		setDarkMode (val,saveLocalStorage){
			this.$vuetify.theme.dark = val;
			if(saveLocalStorage){
				localStorage.setItem('theme', val == true ? 'dark' : 'light')
			}
		},
		initTheme (){
			let queryTheme = this.$route.query.theme;
			let localStorageTheme = localStorage.getItem('theme');
			if(queryTheme){
				this.setDarkMode(queryTheme == 'dark',true);
			}
			else if(localStorageTheme){
				this.setDarkMode(localStorageTheme == 'dark',false)
			}
			else if (window.matchMedia) {
				if(window.matchMedia('(prefers-color-scheme: dark)').matches){
					return this.setDarkMode(true,false);
				} else {
					return this.setDarkMode(false,false);
				}
			}
		},
	},
};
</script>

<style lang="scss">

$font-size-base: 1.6rem;
$spacer: 1.6rem;
@import 'reset-css';
@import "./assets/variables.scss";
@import "./assets/styles.scss";



#app {
	margin: 0;
	padding: 0;
	border: 0;
	min-height: inherit;
	width: 100%;

	//@media (min-width: map-get($grid-breakpoints, md)) {
		display: flex;
		flex-flow: column;
	//}

	//justify-content: stretch;
	//background-color: $bg-color;
	//IE 11 image fix
	-ms-interpolation-mode: bicubic;
}

body {
  margin: 0;
  min-height: inherit;
}

</style>
